/**
 * This file contains a variety of functions borrowed from the explore-logs project.
 * Functions have been copied exactly, but may have been unintentionally altered by linter rules,
 * and minor style preference-related changes.
 *
 * This file is divided using URLs indicating which files & revisions code had been borrowed from.
 * At the top, a few helper declarations have been made to declare types or provide exportable wrappers.
 *
 * In some cases alterations were required. These will be indicated through additional comments.
 * Items that did not need to imported outside of this file have had their "export" directives removed.
 */

import { NodeType, SyntaxNode, Tree } from '@lezer/common';
import { groupBy } from 'lodash';

import { AdHocVariableFilter } from '@grafana/data';
import { Identifier, Matcher, parser, String } from '@grafana/lezer-logql';

// Wrapper function for a non-exported explore-logs function
export function exportedParseSelectorFilter(query: string) {
  const filter: IndexedLabelFilter[] = [];
  parseLabelFilters(query, filter);
  return filter;
}

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/query.ts

function getLogQLLabelGroups(filters: AdHocVariableFilter[]) {
  const positive = filters.filter((filter) => isOperatorInclusive(filter.operator));
  const negative = filters.filter((filter) => isOperatorExclusive(filter.operator));

  const positiveGroups = groupBy(positive, (filter) => filter.key);
  const negativeGroups = groupBy(negative, (filter) => filter.key);
  return { negativeGroups, positiveGroups };
}

export function getLogQLLabelFilters(filters: AdHocVariableFilter[]) {
  const { negativeGroups, positiveGroups } = getLogQLLabelGroups(filters);

  let positiveFilters: string[] = [];
  for (const key in positiveGroups) {
    const values = positiveGroups[key].map((filter) => filter.value);
    positiveFilters.push(
      values.length === 1
        ? renderMetadata(positiveGroups[key][0])
        : renderRegexLabelFilter(key, values, FilterOp.RegexEqual)
    );
  }

  let negativeFilters: string[] = [];
  for (const key in negativeGroups) {
    const values = negativeGroups[key].map((filter) => filter.value);
    negativeFilters.push(
      values.length === 1
        ? renderMetadata(negativeGroups[key][0])
        : renderRegexLabelFilter(key, values, FilterOp.RegexNotEqual)
    );
  }

  return { negativeFilters, positiveFilters };
}

function renderMetadata(filter: AdHocVariableFilter) {
  // If the filter value is an empty string, we don't want to wrap it in backticks!
  if (filter.value === EMPTY_VARIABLE_VALUE) {
    return `${filter.key}${filter.operator}${filter.value}`;
  }
  return `${filter.key}${filter.operator}${JSON.stringify(filter.value)}`; // Changed this to use " instead of `, via stringify
}

export function renderRegexLabelFilter(key: string, values: string[], operator: FilterOp) {
  return `${key}${operator}${JSON.stringify(values.join('|'))}`; // Changed this to use stringify in case we need to escape "
}

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/filterTypes.ts
enum FilterOp {
  NotEqual = '!=',
  RegexNotEqual = '!~',
  lt = '<',
  lte = '<=',
  Equal = '=',
  RegexEqual = '=~',
  gt = '>',
  gte = '>=',
}

type IndexedLabelFilter = {
  key: string;
  operator: FilterOp;
  type?: LabelType;
  value: string;
};

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/fieldTypes.ts
enum LabelType {
  Indexed = 'I',
  Parsed = 'P',
  StructuredMetadata = 'S',
}

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/logqlMatchers.ts
const FilterOperator = FilterOp;

function parseLabelFilters(query: string, filter: IndexedLabelFilter[]) {
  // `Matcher` will select field filters as well as indexed label filters
  const allMatcher = getNodesFromQuery(query, [Matcher]);
  for (const matcher of allMatcher) {
    const identifierPosition = getAllPositionsInNodeByType(matcher, Identifier);
    const valuePosition = getAllPositionsInNodeByType(matcher, String);
    const operator = query.substring(identifierPosition[0]?.to, valuePosition[0]?.from);
    const key = identifierPosition[0].getExpression(query);
    const value = valuePosition.map((position) => query.substring(position.from + 1, position.to - 1))[0];

    if (
      !key ||
      !value ||
      (operator !== FilterOperator.NotEqual &&
        operator !== FilterOperator.Equal &&
        operator !== FilterOperator.RegexEqual &&
        operator !== FilterOperator.RegexNotEqual)
    ) {
      continue;
    }

    filter.push({
      key,
      operator,
      type: LabelType.Indexed,
      value,
    });
  }
}

function getNodesFromQuery(query: string, nodeTypes?: number[]): SyntaxNode[] {
  const nodes: SyntaxNode[] = [];
  const tree: Tree = parser.parse(query);
  tree.iterate({
    enter: (node): false | void => {
      if (nodeTypes === undefined || nodeTypes.includes(node.type.id)) {
        nodes.push(node.node);
      }
    },
  });
  return nodes;
}

function getAllPositionsInNodeByType(node: SyntaxNode, type: number): NodePosition[] {
  if (node.type.id === type) {
    return [NodePosition.fromNode(node)];
  }

  const positions: NodePosition[] = [];
  let pos = 0;
  let child = node.childAfter(pos);
  while (child) {
    positions.push(...getAllPositionsInNodeByType(child, type));
    pos = child.to;
    child = node.childAfter(pos);
  }
  return positions;
}

class NodePosition {
  from: number;
  to: number;
  type?: NodeType;
  syntaxNode?: SyntaxNode;

  constructor(from: number, to: number, syntaxNode?: SyntaxNode, type?: NodeType) {
    this.from = from;
    this.to = to;
    this.type = type;
    this.syntaxNode = syntaxNode;
  }

  static fromNode(node: SyntaxNode): NodePosition {
    return new NodePosition(node.from, node.to, node, node.type);
  }

  contains(position: NodePosition): boolean {
    return this.from <= position.from && this.to >= position.to;
  }

  getExpression(query: string): string {
    return query.substring(this.from, this.to);
  }
}

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/operators.ts

const isOperatorInclusive = (op: string | FilterOp): boolean => {
  return op === FilterOp.Equal || op === FilterOp.RegexEqual;
};
const isOperatorExclusive = (op: string | FilterOp): boolean => {
  return op === FilterOp.NotEqual || op === FilterOp.RegexNotEqual;
};

// https://github.com/grafana/explore-logs/blob/b07c41283f0b6f94d9f5deebbd97d8c97c66f6a0/src/services/variables.ts

const EMPTY_VARIABLE_VALUE = '""';
