import { AppPlugin } from '@grafana/data';
import { config } from '@grafana/runtime';

import { register } from './extensions/temporary-exemptions';
import { App } from './pages/App';
import { AppPluginSettings } from './pages/AppConfig';
import { getUserPermissions } from './utils/grafana';
import { NoAccess } from '@/pages/NoAccess';

if (process.env.NODE_ENV === 'development') {
  if (process.env.ENABLE_MSW === 'true') {
    const { worker } = require('./_mocks_/browser');
    worker.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        options: {
          scope: '/a/',
        },
        url: '/public/msw/mockServiceWorker.js',
      },
    });
  }
  if (process.env.APP_URL) {
    config.appUrl = process.env.APP_URL;
  }
}

export const plugin = new AppPlugin<AppPluginSettings>();

const userPermissions = getUserPermissions();

if (userPermissions.canAccessPlugin) {
  plugin.setRootPage(App);
  register(plugin);
} else {
  plugin.setRootPage(NoAccess);
}
