import { AppPlugin } from '@grafana/data';

import { TemporaryExemptions } from './TemporaryExemptions';
import importedPluginJson from '@/plugin.json';

const { exposedComponents } = importedPluginJson.extensions;

function getExposedComponentMetadata(id: string) {
  const extension = exposedComponents.find((comp) => comp.id === id);
  if (!extension) {
    // Internal developer error.
    throw new Error(`Could not find ${id}. Available extension ids are: ${exposedComponents.map((comp) => comp.id)}`);
  }
  return extension;
}

export function register(plugin: AppPlugin) {
  plugin.exposeComponent({
    ...getExposedComponentMetadata('grafana-adaptivelogs-app/temporary-exemptions/v1')!,
    component: TemporaryExemptions,
  });
}
