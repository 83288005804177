import React from 'react';

import { reportInteraction } from '@grafana/runtime';
import { ConfirmModal } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmationModalLoader';
import { SavingsPreviewPanels } from '../SavingsPreviewPanels';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useSelectedItems } from '@/hooks/context-hooks';
import {
  USER_ACTION_CONTEXT,
  USER_ACTION_ENTITY,
  USER_ACTION_MODE_APPLY_ALL_RECOMMENDATIONS,
  USER_ACTION_MODE_APPLY_SELECTED_RECOMMENDATIONS,
} from '@/utils/constants';
import { noop } from '@/utils/methods';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ApplyModal({ isOpen, onClose }: Props) {
  const { data: recommendations, isFetching: recommendationsIsFetching } = useRecommendations();
  const { isPending: recommendationsUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const { clearSelection, selectedItems } = useSelectedItems();

  const onConfirm = async () => {
    const recommendationsToUpdate = recommendations
      .getSelectedItems(selectedItems)
      .filter((rec) => !rec.locked && rec.configured_drop_rate !== rec.recommended_drop_rate) // Only update unlocked recs where recommended is different
      .map((rec) => ({ ...rec, configured_drop_rate: rec.recommended_drop_rate })); // Set configured to recommended
    await updateRecommendationsAsync(recommendationsToUpdate);

    if (!selectedItems || selectedItems.size === 0) {
      reportInteraction('g_adaptive_telemetry_apply', {
        context: USER_ACTION_CONTEXT,
        entity: USER_ACTION_ENTITY,
        mode: USER_ACTION_MODE_APPLY_ALL_RECOMMENDATIONS,
      });
    } else {
      reportInteraction('g_adaptive_telemetry_apply', {
        context: USER_ACTION_CONTEXT,
        entity: USER_ACTION_ENTITY,
        entity_count: selectedItems.size,
        mode: USER_ACTION_MODE_APPLY_SELECTED_RECOMMENDATIONS,
      });
    }

    clearSelection();
  };

  const busy = recommendationsUpdating || recommendationsIsFetching;

  const getConfirmButtonText = (isLoading?: boolean) => {
    return isLoading ? ((<ConfirmModalLoader text="Applying..." />) as unknown as string) : 'Apply';
  };

  return (
    <ConfirmModal
      title="Apply recommended drop rates"
      isOpen={isOpen}
      onDismiss={busy ? noop : onClose}
      confirmText={getConfirmButtonText(busy)}
      dismissText="Cancel"
      confirmButtonVariant="primary"
      onConfirm={async () => {
        await onConfirm();

        onClose();
      }}
      body={
        <>
          {selectedItems.size
            ? 'This action will apply the selected patterns to their recommended rates'
            : 'This action will apply all patterns to their recommended rates'}
          . Are you sure you want to apply changes?
          <SavingsPreviewPanels />
        </>
      }
    />
  );
}
