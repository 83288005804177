import { rangeUtil } from '@grafana/data';

import { EXEMPTION_EMPERICAL_STARTUP_DELAY } from './constants';
import { Exemption } from '@/api/types';
import { SelectorFilter } from '@/types/selector';
import { parseSelector, selectorSortComparator, selectorToString } from '@/utils/selector';

type ProcessedExemption = {
  item: Exemption;
  normalizedSelector: string;
};

type ExpiringProcessedExemption = ProcessedExemption & {
  durationMs: number;
  expiryDate: Date;
};

export function getActiveExemptions(exemptions: Exemption[]) {
  const now = new Date();

  // Build a map from normalized selector to a list of activeExemptions that match it
  const expiringExemptionsBySelector = new Map<string, ExpiringProcessedExemption[]>();
  const nonExpiringExemptionsBySelector = new Map<string, ProcessedExemption[]>();

  function add<E extends ProcessedExemption>(map: Map<string, E[]>, ex: E) {
    let exemptions = map.get(ex.normalizedSelector);
    if (!exemptions) {
      exemptions = [] as E[];
      map.set(ex.normalizedSelector, exemptions);
    }
    exemptions.push(ex);
  }

  exemptions.forEach((ex) => {
    const { active_interval, expires_at, stream_selector } = ex;

    const processed: ProcessedExemption = {
      item: ex,
      normalizedSelector: getNormalizedSelectorString(parseSelector(stream_selector)),
    };

    if (active_interval && expires_at) {
      const durationMs = rangeUtil.intervalToMs(active_interval);
      const expiryDate = new Date(expires_at);

      if (expiryDate > now) {
        // If we have passed the expiry date, we filter it out
        add(expiringExemptionsBySelector, { ...processed, durationMs, expiryDate });
      }
    } else {
      add(nonExpiringExemptionsBySelector, processed);
    }
  });

  // Now sort each expiring exemption with the same selector so that the latest comes first
  for (const exemptions of expiringExemptionsBySelector.values()) {
    exemptions.sort((a, b) => b.expiryDate.getTime() - a.expiryDate.getTime());
  }

  function getMatches(selectorFilters: SelectorFilter[]): ExemptionMatches {
    const key = getNormalizedSelectorString(selectorFilters);

    const expiring = expiringExemptionsBySelector.get(key) || [];
    const nonExpiring = nonExpiringExemptionsBySelector.get(key) || [];

    const latestExpiringMatch = expiring.at(0);

    // Do not record time stamps if we match a nonExpiring exemption
    if (!latestExpiringMatch || nonExpiring.length) {
      return {
        expiring,
        nonExpiring,
      };
    }

    const expiry = latestExpiringMatch.expiryDate.getTime();
    const activation = expiry + EXEMPTION_EMPERICAL_STARTUP_DELAY - latestExpiringMatch.durationMs;

    return {
      expiring,
      nonExpiring,
      timestamps: {
        activation,
        expiry,
      },
    };
  }

  return { getMatches };
}

export type ExemptionMatches = {
  expiring: ExpiringProcessedExemption[];
  nonExpiring: ProcessedExemption[];
  timestamps?: {
    activation: number;
    expiry: number;
  };
};

function getNormalizedSelectorString(selector: SelectorFilter[]) {
  const sorted = [...selector].sort(selectorSortComparator);
  return selectorToString(sorted);
}
