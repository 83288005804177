import { trim } from 'lodash';

import { AdHocVariableFilter } from '@grafana/data';

import { exportedParseSelectorFilter, getLogQLLabelFilters } from './explore-logs-utils';
import { SelectorFilter } from '@/types/selector';

export function selectorSortComparator(a: AdHocVariableFilter, b: AdHocVariableFilter) {
  const [arrayA, arrayB] = [a, b].map(({ key, operator, value }) => [key, operator, value]);

  if (arrayA < arrayB) {
    return -1;
  } else if (arrayA > arrayB) {
    return 1;
  }
  return 0;
}

export function selectorToString(selector: SelectorFilter[] = []) {
  const { negativeFilters, positiveFilters } = getLogQLLabelFilters(selector);
  const result = `{${trim(`${positiveFilters.join(', ')}, ${negativeFilters.join(', ')}`, ' ,')}}`;
  return result;
}

export function selectorToPrettyString(selector: SelectorFilter[] = []) {
  const { negativeFilters, positiveFilters } = getLogQLLabelFilters(selector);
  const COMMA_NEWLINE_INDENT = ',\n  '; // Separates with comma, followed by a newline and indent
  // Similar to `selectorToString` above, but more whitespace.
  const result =
    `{\n  ` + // Indent first line after {
    `${trim(
      `${positiveFilters.join(COMMA_NEWLINE_INDENT)}` +
        COMMA_NEWLINE_INDENT + // Comma between two lists
        `${negativeFilters.join(COMMA_NEWLINE_INDENT)}`,
      COMMA_NEWLINE_INDENT // trimming this out if the second or first list is empty
    )}` +
    `\n}`;
  return result;
}

export function parseSelector(query: string) {
  return exportedParseSelectorFilter(query).map(({ type, ...rest }) => rest);
}
