import React, { useEffect, useMemo, useReducer } from 'react';

import { rangeUtil } from '@grafana/data';
import { ButtonVariant, Icon, IconName, Spinner, Stack } from '@grafana/ui';

import type { ExemptionMatches } from './active-exemptions';
import { TEMPORARY_EXEMPTION_DURATION } from './constants';
import { formatExemptionDate } from '@/utils/formats';

function getNowMsec() {
  return new Date().getTime();
}

export function useContent({ nonExpiring, timestamps }: ExemptionMatches) {
  const [now, updateNow] = useReducer(getNowMsec, getNowMsec());

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    const update = () => {
      updateNow();
      timeout = setTimeout(update, 5000);
    };
    update();
    return () => clearTimeout(timeout);
  }, []);

  const display: DisplayContent = useMemo(() => {
    if (nonExpiring.length) {
      return HAS_NON_EXPIRING_EXEMPTION;
    }

    const { expiryDate, msecUntilExpiry, template } = determineDisplayWithExpiry(timestamps, now);
    const expiryMinutes = Math.ceil(msecUntilExpiry / 1000 / 60);
    const expiryDateFormatted = formatExemptionDate(expiryDate);

    return {
      ...template,
      modalExpiryMessage: template.modalExpiryMessageTemplate(expiryMinutes, expiryDateFormatted),
    };
  }, [now, timestamps, nonExpiring]);

  return display;
}

type DisplayContent = {
  buttonIcon: IconName;
  buttonText: string;
  modalApplyButtonText: string;
  modalApplyButtonVariant: ButtonVariant;
  modalDeleteInstructions: string;
  modalExpiryMessage: string;
  modalIntro: string;
  modalTitle: string;
  modalWarning: React.JSX.Element | null;
};

type DisplayContentWithExpiryTemplate = Omit<DisplayContent, 'modalExpiryMessage'> & {
  modalExpiryMessageTemplate: (minutes: number, date: string) => string;
};

const NO_ACTIVE_EXEMPTIONS: DisplayContentWithExpiryTemplate = {
  buttonIcon: 'pause',
  buttonText: 'Adaptive Logs',
  modalApplyButtonText: 'Apply exemption',
  modalApplyButtonVariant: 'primary',
  modalDeleteInstructions:
    'You can abort or remove exemptions any time by using the same button or by visiting Adaptive Logs > Configuration > Exemptions section.',
  modalExpiryMessageTemplate: (minutes, date) => `Exemption will expire in ${minutes} minutes at ${date}.`,
  modalIntro:
    'Create a temporary exemption for log lines matching patterns in Adaptive Logs and keep all logs related to the current selector:',
  modalTitle: 'Pause Adaptive Logs',
  modalWarning: <div>This will temporarily increase the volume of logs.</div>,
};

const HAS_NON_EXPIRING_EXEMPTION: DisplayContent = {
  ...NO_ACTIVE_EXEMPTIONS,
  buttonIcon: 'exclamation-triangle',
  buttonText: 'Adaptive Logs Exemption',
  modalApplyButtonVariant: 'secondary',
  modalDeleteInstructions:
    'You can only remove exemptions by visiting Adaptive Logs > Configuration > Exemptions section.',
  modalExpiryMessage: 'This exemption does not have an expiry date.',
  modalIntro:
    'There is a non-expiring exemption for log lines matching patterns in Adaptive Logs to keep all logs related to the current selector:',
  modalTitle: 'Adaptive Logs Exemption',
  modalWarning: (
    <Stack alignItems={'center'}>
      <Icon name={'exclamation-triangle'} />
      <span>
        While non-expiring exemptions are configured for a selector, it is not possible to apply temporary exemptions.
      </span>
    </Stack>
  ),
};

const ACTIVE_EXPIRING_EXEMPTION: DisplayContentWithExpiryTemplate = {
  ...NO_ACTIVE_EXEMPTIONS,
  buttonIcon: 'check',
  buttonText: 'Adaptive Logs Paused',
  modalApplyButtonText: 'Remove exemption',
  modalApplyButtonVariant: 'destructive',
  modalDeleteInstructions: 'You can remove the exemption.',
  modalIntro:
    'There is a temporary exemption for log lines matching patterns in Adaptive Logs to keep all logs related to the current selector:',
  modalTitle: 'Adaptive Logs Paused',
  modalWarning: null,
};

const ACTIVE_EXPIRING_EXEMPTION_PENDING: DisplayContentWithExpiryTemplate = {
  ...ACTIVE_EXPIRING_EXEMPTION,
  buttonIcon: 'fa fa-spinner',
  buttonText: 'Pausing Adaptive Logs',
  modalTitle: 'Pausing Adaptive Logs',
  modalWarning: (
    <Stack alignItems={'center'}>
      <Spinner />
      <span>The exemption will activate shortly.</span>
    </Stack>
  ),
};

function determineDisplayWithExpiry(timestamps: ExemptionMatches['timestamps'], now: number) {
  if (!timestamps) {
    const msecUntilExpiry = rangeUtil.intervalToMs(TEMPORARY_EXEMPTION_DURATION);
    return {
      expiryDate: new Date(now + msecUntilExpiry),
      msecUntilExpiry,
      template: NO_ACTIVE_EXEMPTIONS,
    };
  }

  const { activation, expiry } = timestamps;

  const expiryArgs = {
    expiryDate: expiry,
    msecUntilExpiry: expiry - now,
  };

  const msecUntilActivation = activation - now;

  if (msecUntilActivation === undefined) {
    return { ...expiryArgs, template: NO_ACTIVE_EXEMPTIONS };
  }

  if (msecUntilActivation > 0) {
    return { ...expiryArgs, template: ACTIVE_EXPIRING_EXEMPTION_PENDING };
  } else {
    return { ...expiryArgs, template: ACTIVE_EXPIRING_EXEMPTION };
  }
}
