import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import { FeatureState, GrafanaTheme2, usePluginContext } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, ConfirmModal, FeatureBadge, Icon, Spinner, Stack, Tooltip, useStyles2 } from '@grafana/ui';

import { getActiveExemptions } from './active-exemptions';
import { TEMPORARY_EXEMPTION_DURATION } from './constants';
import { useContent } from './content-hooks';
import { Exemption } from '@/api/types';
import { useCreateExemptionMutation, useDeleteExemptionMutation, useExemptions } from '@/hooks/api-hooks';
import { AppPluginSettings } from '@/pages/AppConfig';
import { SelectorFilter } from '@/types/selector';
import { selectorToPrettyString, selectorToString } from '@/utils/selector';
import { queryClient } from '@/utils/state';
import { onlyKeepLettersInLowerCase } from '@/utils/string';

type Props = {
  /** An ordered list of lower-case [a-z]+ string identifiers to provide context clues of where this component is being embedded and how we might want to consider displaying it */
  contextHints?: string[];
  /** Currently selected data source */
  dataSourceUid?: string;
  /** The stream selector, broken down into a list of structured subselector filter items */
  streamSelector?: SelectorFilter[];
};

export function TemporaryExemptions({ contextHints, dataSourceUid, streamSelector }: Props) {
  const jsonData = usePluginContext()?.meta.jsonData as AppPluginSettings;

  const contextId = useMemo(() => {
    if (!contextHints) {
      // If no context hints were provided by the consumer of this extension, we won't provide an identifier
      return '';
    }

    // Clean up the given context hints, converting to `dashseparated-context-hints` format.
    return contextHints
      .map(onlyKeepLettersInLowerCase)
      .filter((s) => !!s)
      .join('-');
  }, [contextHints]);

  if (jsonData?.managedLogsDataSourceUid !== dataSourceUid) {
    return null;
  }

  if (!streamSelector || streamSelector.length === 0) {
    return null;
  }

  return (
    <ErrorBoundary
      fallbackRender={(detail) => (
        <Tooltip content={JSON.stringify(detail.error)}>
          <Icon name="exclamation-triangle" />
        </Tooltip>
      )}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Spinner />}>
          <ExemptionsButton streamSelector={streamSelector} contextId={contextId} />
        </Suspense>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

type ExemptionsButtonProps = Pick<Props, 'streamSelector'> & { contextId: string };

export function ExemptionsButton({ contextId, streamSelector }: ExemptionsButtonProps) {
  const [open, setOpen] = useState(false);

  const createExemption = useCreateExemptionMutation();
  const deleteExemption = useDeleteExemptionMutation();

  const {
    data: { result: exemptions },
    refetch: refetchExemptions,
  } = useExemptions();

  useEffect(() => {
    refetchExemptions();
  }, [refetchExemptions, streamSelector]);

  const active = useMemo(() => {
    return getActiveExemptions(exemptions);
  }, [exemptions]);

  const matches = useMemo(() => active.getMatches(streamSelector || []), [streamSelector, active]);

  const styles = useStyles2(getStyles);

  const display = useContent(matches);

  const exemptionIsLive = !!matches;

  useEffect(() => {
    let delay = 0;

    if (open || exemptionIsLive) {
      // 60 seconds if it's open or live
      delay = 1000 * 60;
    } else {
      // 5 minutes otherwise
      delay = 1000 * 60 * 5;
    }

    let timeout: ReturnType<typeof setTimeout>;
    const doRefetch = async () => {
      await refetchExemptions();
      timeout = setTimeout(doRefetch, delay);
    };

    doRefetch();
    return () => clearTimeout(timeout);
  }, [exemptionIsLive, open, refetchExemptions]);

  async function confirm() {
    if (matches.expiring.length) {
      for (const ex of matches.expiring) {
        await deleteExemption.mutateAsync(ex.item.id);
      }
    } else {
      const exemption: Pick<Exemption, 'active_interval' | 'stream_selector'> = {
        active_interval: TEMPORARY_EXEMPTION_DURATION,
        stream_selector: selectorToString(streamSelector),
      };

      reportInteraction('g_adaptive_telemetry_pause', {
        context: contextId,
        telemetry: 'logs',
      });
      await createExemption.mutateAsync(exemption);

      refetchExemptions();
    }

    setOpen(false);
  }

  const mutating = createExemption.isPending || deleteExemption.isPending;

  return (
    <>
      <Button
        icon={display.buttonIcon}
        size="sm"
        tooltip="Manage temporary exemptions on Adaptive Logs based on the current label selector."
        variant="secondary"
        fill="outline"
        onClick={() => {
          setOpen(true);
        }}
      >
        {display.buttonText}
      </Button>
      <FeatureBadge
        featureState={FeatureState.preview}
        tooltip="The ability to set temporary exemptions on Adaptive Logs is in public preview."
      />
      <ConfirmModal
        isOpen={open}
        confirmText={display.modalApplyButtonText}
        confirmButtonVariant={display.modalApplyButtonVariant}
        disabled={mutating || !!matches.nonExpiring?.length}
        dismissText="Cancel"
        onDismiss={() => setOpen(false)}
        onConfirm={confirm}
        title={display.modalTitle}
        body={
          <div>
            <Stack direction={'column'} gap={2}>
              <div className={styles.modalPrimary}>{display.modalIntro}</div>
              <pre className={styles.modalSelectorExpression}>{selectorToPrettyString(streamSelector)}</pre>
            </Stack>
            <div className={styles.modalSecondary}>
              <Stack direction={'column'} gap={2}>
                {display.modalWarning}
                <div>{display.modalDeleteInstructions}</div>
                <div>{display.modalExpiryMessage}</div>
              </Stack>
            </div>
          </div>
        }
      />
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    modalPrimary: css({
      ...theme.typography.body,
    }),
    modalSecondary: css({
      ...theme.typography.bodySmall,
      color: theme.colors.text.secondary,
    }),
    modalSelectorExpression: css({
      ...theme.typography.code,
      color: theme.colors.text.secondary,
    }),
  };
}
