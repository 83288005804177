import { useContext, useMemo } from 'react';

import { AdaptiveLogsContext } from '@/context/adaptive-logs-context';
import { getUserPermissions } from '@/utils/grafana';

export const useFilter = () => {
  const { filter, setFilter } = useContext(AdaptiveLogsContext);

  const isFiltered = useMemo(() => {
    if (filter) {
      return true;
    } else {
      return false;
    }
  }, [filter]);

  if (filter === undefined) {
    throw new Error('useFilter must be used within AdaptiveLogsContextProvider');
  }

  return {
    filter,
    isFiltered,
    setFilter,
  };
};

// TODO we can probably include this in the useFilter
export const useServiceNameFilter = () => {
  const { serviceNameFilter, setServiceNameFilter } = useContext(AdaptiveLogsContext);

  if (serviceNameFilter === undefined) {
    throw new Error('useServiceNameFilter must be used within AdaptiveLogsContextProvider');
  }

  return {
    serviceNameFilter,
    setServiceNameFilter,
  };
};

export const useUserPermissions = () => getUserPermissions();

export const useSelectedItems = () => {
  const { selectedItems, setSelectedItems, toggleSelectedItem } = useContext(AdaptiveLogsContext);

  if (!selectedItems) {
    throw new Error('useSelectedItems must be used within AdaptiveLogsContextProvider');
  }

  return {
    clearSelection: () => {
      setSelectedItems(new Set<string>());
    },
    selectedItems,
    selectedItemsAsArray: Array.from(selectedItems),
    setSelectedItems,
    toggleSelectedItem,
  };
};

export const useTableData = () => {
  const { setTableData, tableData } = useContext(AdaptiveLogsContext);

  if (!tableData) {
    throw new Error('useTableData must be used within AdaptiveLogsContextProvider');
  }

  return { setTableData, tableData };
};

export const useShowSelected = () => {
  const { setShowSelected, showSelected } = useContext(AdaptiveLogsContext);

  if (showSelected === undefined) {
    throw new Error('useShowSelected must be used within AdaptiveLogsContextProvider');
  }

  return { setShowSelected, showSelected };
};
