import React, { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { inRange as lodashInRange, isString as lodashIsString } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { reportInteraction } from '@grafana/runtime';
import { Button, Checkbox, ConfirmModal, Field, Icon, Input, Stack, useStyles2 } from '@grafana/ui';

import { getVolumeAffectableByPatternDropRate } from './bulk-pattern-util';
import {
  calculateChangeInDroppedVolume,
  calculateChangeInDroppedVolumeForClearingPerServiceDropRates,
  getSegmentEditorInfo,
} from './bulk-segment-util';
import { PatternRecommendation, PatternUpdateModel } from '@/api/types';
import { InfoNumberPill } from '@/components/InfoNumberPill';
import { getSummaryVolumes } from '@/components/SavingsPreviewPanels/utils';
import { SegmentEditor } from '@/components/SegmentEditor';
import { useSegmentEditorState } from '@/components/SegmentEditor/hooks';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useSelectedItems, useServiceNameFilter, useUserPermissions } from '@/hooks/context-hooks';
import {
  DROP_RATE_UPPER_LIMIT_EXCLUSIVE,
  ERROR_DROP_PERCENTAGE_RANGE,
  UPDATED_RATE_INPUT_WIDTH,
  USER_ACTION_CONTEXT,
  USER_ACTION_ENTITY,
  USER_ACTION_MODE_BULK_SELECT,
} from '@/utils/constants';
import { isDefined } from '@/utils/filter';
import { formatNumber, getValueAndUnit } from '@/utils/formats';
import { noop } from '@/utils/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  infoIcon: css({
    marginTop: theme.spacing(0.25),
  }),
  infoText: css({
    ...theme.typography.bodySmall,
  }),
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  modalText: css({
    ...theme.typography.body,
    color: theme.colors.text.secondary,
    paddingBottom: theme.spacing(1.5),
  }),
  updatedRateInput: css({
    marginTop: theme.spacing(1),
  }),
});

export const BatchEditPattern = () => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const [updatedRate, setUpdatedRate] = useState('');
  const userPermissions = useUserPermissions();
  const { isPending: recommendationUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const { data: recommendations } = useRecommendations();
  const { clearSelection, selectedItems, selectedItemsAsArray } = useSelectedItems();
  const { serviceNameFilter } = useServiceNameFilter();

  const [bulkClearSegmentRates, setBulkClearSegmentRates] = useState(false);

  const selectedRecommendations = useMemo(
    () =>
      selectedItemsAsArray.map((pattern) => {
        return recommendations.mappedItems.get(pattern)!;
      }),
    [recommendations.mappedItems, selectedItemsAsArray]
  );

  const segments = useMemo(() => {
    if (!isOpen) {
      return [];
    }

    return serviceNameFilter
      .map(({ text: serviceName, value: segment }) => {
        if (!segment || !lodashIsString(serviceName)) {
          return undefined;
        }

        return { ...getSegmentEditorInfo(selectedRecommendations, segment), serviceName };
      })
      .filter(isDefined);
  }, [isOpen, selectedRecommendations, serviceNameFilter]);

  const hasOverrides = useMemo(() => {
    for (const { patterns, segment } of segments) {
      for (const pattern of patterns) {
        if (pattern.segments?.[segment]?.configured_drop_rate != null) {
          return true;
        }
      }
    }
    return false;
  }, [segments]);

  const { totalAffectableByPatternDropRate, totalDropped, totalDroppedBySegmentRates } = useMemo(() => {
    const { totalDropped } = getSummaryVolumes(selectedRecommendations);
    const { totalAffectableByPatternDropRate, totalDroppedBySegmentRates } =
      getVolumeAffectableByPatternDropRate(selectedRecommendations);

    return { totalAffectableByPatternDropRate, totalDropped, totalDroppedBySegmentRates };
  }, [selectedRecommendations]);

  const projectedDroppedVolumeWithNewPatternDropRate = useMemo(() => {
    const rate = Number(updatedRate) / 100;

    return rate * totalAffectableByPatternDropRate + totalDroppedBySegmentRates;
  }, [totalAffectableByPatternDropRate, totalDroppedBySegmentRates, updatedRate]);

  const {
    errorSegments,
    modifiedSegments,
    reset,
    setSegmentDropRate,
    updates: segmentEditorUpdates,
  } = useSegmentEditorState(segments);

  const onConfirm = async () => {
    // Get the current rate from the rate editor -- null if not set
    const rate = updatedRate.trim() !== '' ? Number(updatedRate) : null;

    // Get the current segment editor values
    const segmentUpdateModel = { ...segmentEditorUpdates.current! };

    // Ensure that all possible segments set the `configured_drop_rate` to undefined
    if (bulkClearSegmentRates) {
      Object.values(segments).forEach(({ segment }) => {
        segmentUpdateModel[segment] = { ...segmentUpdateModel[segment], configured_drop_rate: undefined };
      });
    }

    /** We only want to send the segments with corresponding attributions per pattern */
    function getRelevantSegments(rec: PatternRecommendation) {
      return Object.fromEntries(Object.entries(segmentUpdateModel).filter(([key]) => rec.attribution[key] != null));
    }

    const recommendationsToUpdate: PatternUpdateModel[] = recommendations
      .getSelectedItems(selectedItems)
      .filter((rec) => !rec.locked /*&& rec.configured_drop_rate !== rate && segmentCount > 0*/)
      .map((rec) => {
        const patternUpdate: PatternUpdateModel = {
          pattern: rec.pattern,
        };
        if (rate !== null) {
          patternUpdate.configured_drop_rate = rate;
        }
        const segments = getRelevantSegments(rec);
        if (Object.keys(segments).length > 0) {
          patternUpdate.segments = segments;
        }

        return patternUpdate;
      });
    // Note, this is intentionally not considering the recommended rate.

    await updateRecommendationsAsync(recommendationsToUpdate);

    reportInteraction('g_adaptive_telemetry_apply', {
      context: USER_ACTION_CONTEXT,
      entity: USER_ACTION_ENTITY,
      entity_count: recommendationsToUpdate.length,
      mode: USER_ACTION_MODE_BULK_SELECT,
    });

    clearSelection();
    setUpdatedRate('');
    setIsOpen(false);
  };
  const dropRateOutOfRange = !lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE);
  const noValuesModified = !updatedRate && modifiedSegments.size === 0 && bulkClearSegmentRates === false;

  const invalidInput = noValuesModified || dropRateOutOfRange || errorSegments.size > 0;

  const BatchEditPatternButton = () => {
    const buttonText = serviceNameFilter.length ? 'Bulk edit service drop rates' : 'Bulk edit drop rates';

    if (!userPermissions.canApplyPatterns || !selectedItems.size) {
      return (
        <Button
          tooltip={
            !userPermissions.canApplyPatterns
              ? 'You do not have permission for this action.'
              : 'No patterns are selected.'
          }
          variant="secondary"
          disabled
          onClick={noop}
        >
          {buttonText}
        </Button>
      );
    }

    return (
      <Button
        variant="secondary"
        onClick={() => {
          reset();
          setBulkClearSegmentRates(false);
          setIsOpen(true);
        }}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <BatchEditPatternButton />
      {/* Bulk modal for no selected segments / service names */}
      <ConfirmModal
        title="Edit multiple patterns drop rate"
        isOpen={isOpen && segments.length === 0}
        body={
          <div className={styles.modalBody}>
            <div className={styles.modalText}>
              {(selectedItems.size === 1
                ? `Set up pattern drop rate for ${selectedItems.size} selected pattern. `
                : `Set up pattern drop rate for ${selectedItems.size} selected patterns. `) +
                `The current configuration provides a total ${getValueAndUnit(totalDropped, 2)} of projected savings.`}
            </div>

            <Field
              label="Drop rate (all selected patterns)"
              description="Default (per service overrides will not be updated)"
              invalid={!lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)}
              error={ERROR_DROP_PERCENTAGE_RANGE}
            >
              <Input
                className={styles.updatedRateInput}
                autoFocus
                aria-label="batch-custom-rate-input"
                width={UPDATED_RATE_INPUT_WIDTH}
                type="number"
                value={updatedRate}
                suffix="%"
                onChange={(v) => setUpdatedRate(v.currentTarget.value)}
              />
            </Field>
            {!invalidInput && (
              <div className={styles.modalText}>
                Projected savings after you apply changes:{' '}
                {getValueAndUnit(projectedDroppedVolumeWithNewPatternDropRate, 2)}
              </div>
            )}
          </div>
        }
        confirmText={recommendationUpdating ? 'Applying...' : 'Apply'}
        disabled={noValuesModified || dropRateOutOfRange || errorSegments.size > 0}
        confirmButtonVariant={recommendationUpdating ? 'secondary' : 'primary'}
        onConfirm={recommendationUpdating ? noop : onConfirm}
        onDismiss={
          recommendationUpdating
            ? noop
            : () => {
                setUpdatedRate('');
                setIsOpen(false);
              }
        }
      />
      <ConfirmModal
        title="Edit multiple patterns per-service drop rate"
        isOpen={isOpen && segments.length > 0}
        body={
          <div className={styles.modalBody}>
            <Stack>
              <Icon className={styles.infoIcon} name="info-circle" size="xs" />
              <div className={cx(styles.modalText, styles.infoText)}>
                You are filtering based on service(s). You will change drop rates for selected services only, for all
                select patterns. Clear the service filter to edit selected pattern drop rates.
              </div>
            </Stack>
            <div className={styles.modalText}>
              {(selectedItems.size === 1
                ? `Set up per-service drop rates for ${selectedItems.size} selected pattern. `
                : `Set up per-service drop rates for ${selectedItems.size} selected patterns. `) +
                `The current configuration provides a total ${getValueAndUnit(totalDropped, 2)} of projected savings. `}
              This feature is currently in preview.
            </div>
            <SegmentEditor
              errorSegments={errorSegments}
              rightColumnHeader="No. of patterns affected"
              getPlaceholderText={({
                allSegmentDropRatesEqual,
                averageSegmentDropRate,
                patternsWithSegmentDropRate,
              }) => {
                if (patternsWithSegmentDropRate.length === 0) {
                  return undefined;
                }
                if (allSegmentDropRatesEqual) {
                  return formatNumber(averageSegmentDropRate);
                }
                return `~${formatNumber(averageSegmentDropRate)}`;
              }}
              segmentSuffixRender={({
                allSegmentDropRatesEqual,
                averageSegmentDropRate,
                patternsWithSegmentDropRate,
                segment,
                serviceName,
              }) => {
                const count = patternsWithSegmentDropRate.length;

                if (count === 0) {
                  // No overrides to report on
                  return null;
                }

                const displayDropRate = formatNumber(averageSegmentDropRate, 0);
                const currentDropRateMessage = allSegmentDropRatesEqual
                  ? `${displayDropRate}% drop rate`
                  : `~${displayDropRate}% average drop rate`;

                let tooltip: string;
                if (modifiedSegments.has(segment)) {
                  tooltip =
                    count === 1
                      ? `1 pattern currently has an override ${currentDropRateMessage} for ${serviceName}, which will be replaced if this operation is applied.`
                      : `${count} patterns currently have an override ${currentDropRateMessage} for ${serviceName}, which will be replaced if this operation is applied.`;
                } else if (bulkClearSegmentRates) {
                  tooltip =
                    count === 1
                      ? `1 pattern currently has an override ${currentDropRateMessage} for ${serviceName}, which will be cleared if this operation is applied.`
                      : `${count} patterns currently have an override ${currentDropRateMessage} for ${serviceName}, which will be cleared if this operation is applied.`;
                } else {
                  tooltip =
                    count === 1
                      ? `1 pattern currently has an override ${currentDropRateMessage} for ${serviceName}.`
                      : `${count} patterns currently have an override ${currentDropRateMessage} for ${serviceName} for ${serviceName}.`;
                }

                return <InfoNumberPill tooltip={tooltip} value={count} />;
              }}
              rightColumnRenderCell={({ patterns }) => patterns.length}
              segments={segments}
              disabled={bulkClearSegmentRates}
              setSegmentDropRate={setSegmentDropRate}
              modifiedSegments={modifiedSegments}
            />

            {hasOverrides && modifiedSegments.size === 0 && (
              <Field>
                <Checkbox
                  disabled={modifiedSegments.size > 0}
                  checked={bulkClearSegmentRates}
                  onChange={(event) => setBulkClearSegmentRates(event.currentTarget.checked)}
                  label="Reset per-service drop rates overrides (pattern’s drop rate will apply)"
                />
              </Field>
            )}

            {!invalidInput && (
              <div className={styles.modalText}>
                Projected savings after you apply changes:{' '}
                {getValueAndUnit(
                  totalDropped +
                    (bulkClearSegmentRates
                      ? calculateChangeInDroppedVolumeForClearingPerServiceDropRates(segments)
                      : calculateChangeInDroppedVolume(segments, segmentEditorUpdates.current)),
                  2
                )}
              </div>
            )}
          </div>
        }
        confirmText={recommendationUpdating ? 'Applying...' : 'Apply'}
        disabled={invalidInput}
        confirmButtonVariant={recommendationUpdating ? 'secondary' : 'primary'}
        onConfirm={recommendationUpdating ? noop : onConfirm}
        onDismiss={
          recommendationUpdating
            ? noop
            : () => {
                setUpdatedRate('');
                setIsOpen(false);
              }
        }
      />
    </>
  );
};
